import React, {useEffect, useMemo, useState} from "react";
import Icon from "../../../components/Icon";
import {formatDateHeader, formatTimeMessage} from "../../../utils/formatTime";
import { Fragment } from "react";
import {Avatar, Box, Stack} from "@mui/material";
import userDefaultAvatar from "../../../assets/images/user-default.svg";

const Convo = ({participants, isChatGroup, lastMsgRef, messages: initialMessages }) => {

	const currentUser = JSON.parse(localStorage.getItem("currentUser"));

	// console.log("!!! Convo rendering Initial messages:", initialMessages);
	const [messages, setMessages] = useState(initialMessages || []);

	const appendMessage = (newMessage) => {
		setMessages((prevMessages) => [...prevMessages, newMessage]);
	};

	useEffect(() => {
		setMessages(initialMessages || []);
	}, [initialMessages]);

	// Memoized groupedMessages to avoid recalculating unnecessarily
	const groupedMessages = useMemo(() => {
		return messages.reduce((acc, message) => {
			const date = formatDateHeader(new Date(message.sentAt))
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(message);
			return acc;
		}, {});
	}, [messages]);

	const dates = Object.keys(groupedMessages);

	// console.log("Grouped messages:", groupedMessages);
	// console.log("Dates:", dates);

	const cloudFrontDomain = "https://d3uzdvdm8sh9p9.cloudfront.net";

	const replaceImage = (path) => {
		if (path && typeof path === "string") {
			return path.includes("https://trongpham-aws.s3.ap-southeast-2.amazonaws.com")
				? path.replace("https://trongpham-aws.s3.ap-southeast-2.amazonaws.com", cloudFrontDomain)
				: path;
		}
		return path;
	};

	return (
		<>
			{/*messages: {messages.length}*/}
			{/*groupedMessages: {Object.keys(groupedMessages).length}*/}

			{dates.map((date, dateIndex) => {
				const dayMessages = groupedMessages[date];
				return (
					<div key={dateIndex}>
						<div className="chat__date-wrapper">
							<span className="chat__date">{date}</span>
						</div>
						<div className="chat__msg-group">
							{dayMessages.map((message, msgIndex) => {
								const assignRef =
									dateIndex === dates.length - 1 && msgIndex === dayMessages.length - 1
										? lastMsgRef
										: undefined;

								return (
									<Fragment key={message._id} >
										{message.type === "image" ? (
											<div
												className={`chat__msg chat__img-wrapper ${
													message.senderId._id !== currentUser._id
														? "chat__msg--rxd"
														: "chat__msg--sent"
												}`}
												ref={assignRef}
											>
												<img src={replaceImage(message.content)} alt="Message" className="chat__img" />
												<span className="chat__msg-footer">
                                                    <span>{formatTimeMessage(new Date(message.sentAt))}</span>
													{message.senderId._id === currentUser._id && (
														<Icon
															id={
																message.status === "sent"
																	? "singleTick"
																	: "doubleTick"
															}
															aria-label={message.status}
															className={`chat__msg-status-icon ${
																message.status === "read"
																	? "chat__msg-status-icon--blue"
																	: ""
															}`}
														/>
													)}
												</span>
											</div>
										) : message.type === "video" ? (
											<div
												className={`chat__msg chat__video-wrapper ${
													message.senderId._id !== currentUser._id
														? "chat__msg--rxd"
														: "chat__msg--sent"
												}`}
												ref={assignRef}
											>
												<video controls src={message.content} className="chat__video" />
												<span className="chat__msg-footer">
												<span>{formatTimeMessage(new Date(message.sentAt))}</span>
													{message.senderId._id === currentUser._id && (
														<Icon
															id={
																message.status === "sent"
																	? "singleTick"
																	: "doubleTick"
															}
															aria-label={message.status}
															className={`chat__msg-status-icon ${
																message.status === "read"
																	? "chat__msg-status-icon--blue"
																	: ""
															}`}
														/>
													)}
											</span>
											</div>
										) : message.type === "file" ? (
											<div
												className={`chat__msg chat__file-wrapper ${
													message.senderId._id !== currentUser._id
														? "chat__msg--rxd"
														: "chat__msg--sent"
												}`}
												ref={assignRef}
											>
												<a href={message.content} target="_blank" rel="noopener noreferrer" className="chat__file-link">
													<strong>[File]</strong> {message.content.split('/').pop()}
												</a>
												<span className="chat__msg-footer">
												<span>{formatTimeMessage(new Date(message.sentAt))}</span>
													{message.senderId._id === currentUser._id && (
														<Icon
															id={
																message.status === "sent"
																	? "singleTick"
																	: "doubleTick"
															}
															aria-label={message.status}
															className={`chat__msg-status-icon ${
																message.status === "read"
																	? "chat__msg-status-icon--blue"
																	: ""
															}`}
														/>
													)}
											</span>
											</div>
										) : (
											<Box display={"flex"}
												 flexDirection={
													 message.senderId._id === currentUser._id ? "row-reverse" : "row"
												 } gap={1}>
												{isChatGroup && message.senderId._id !== currentUser._id && (
													// 	<span style={{ fontWeight: "bold", color: "green" }}>
													// 		[{message.senderId.name}]&nbsp;
													// </span>
													<Avatar width={10} height={10}
															src={participants.find(p => p._id === message.senderId._id)?.profileImage || userDefaultAvatar}
															alt={message.senderId.name} />
												)}

											<p
												className={`chat__msg ${
													message.senderId._id !== currentUser._id
														? "chat__msg--rxd"
														: "chat__msg--sent"
												}`}
												ref={assignRef}
											>
												<span>{message.content}</span>
												<span className="chat__msg-filler"> </span>
												<span className="chat__msg-footer">
													<span>{formatTimeMessage(new Date(message.sentAt), true)}</span>
													{message.senderId._id === currentUser._id && (
														<Icon
															id={
																message.status === "sent"
																	? "singleTick"
																	: "doubleTick"
															}
															aria-label={message.status}
															className={`chat__msg-status-icon ${
																message.status === "read"
																	? "chat__msg-status-icon--blue"
																	: ""
															}`}
														/>
													)}
												</span>
											</p>
											</Box>
										)}
									</Fragment>
								);
							})}
						</div>
					</div>
				);
			})}
		</>
	);
};

export default Convo;
