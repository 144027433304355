import React, { useEffect, useState } from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Routes, Switch} from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import Sidebar from "./components/Sidebar";
import Chat from "./pages/Chat";
import SigIn from "./pages/SignUp";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { useNavigate } from "react-router-dom";

const userPrefersDark =
	window.matchMedia &&
	window.matchMedia("(prefers-color-scheme: dark)").matches;

function App() {
	const [appLoaded, setAppLoaded] = useState(false);
	const [startLoadProgress, setStartLoadProgress] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const authToken = localStorage.getItem("authToken");
		if (!authToken) {
			navigate("/login");
		}

		// if (userPrefersDark) document.body.classList.add("dark-theme");
		//TODO fix dark mode
		document.body.classList.add("dark-theme");

		stopLoad();
	}, []);

	const stopLoad = () => {
		setStartLoadProgress(true);
		setTimeout(() => setAppLoaded(true), 3000);
	};

	if (!appLoaded) return <Loader done={startLoadProgress} />;

	return (
		<div className="app">
			<p className="app__mobile-message"> Only available on desktop 😊. </p>
			{/*<Router>*/}
					<Routes>
						<Route path="/chat/:id" element={<Chat/>} />
						<Route path="/" element={<Home/>} />
						<Route path="/signup" element={<SignUp/>} />
						<Route path="/login" element={<Login/>} />
					</Routes>

			{/*</Router>*/}
		</div>
	);
}

export default App;
