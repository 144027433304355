import {BoxMemeModal} from "../common/CustomBox";
import {IconButton, Stack, Typography} from "@mui/material";
import {DefaultInput} from "../common/CustomTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {Close} from "@mui/icons-material";
import React from "react";
import apiClient from "../../api/apiClient";


export default function UserProfile({handleClose}) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [isEdit, setIsEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [newName, setNewName] = React.useState(currentUser.name);

    const handleEditName =  (e) => {
        const { value } = e.target;
        // console.log("value", value)
        setNewName(value)
    }

    const getCurrentUser = async () => {
        try {
            const { data } = await apiClient.get("/user/");
            localStorage.setItem("currentUser", JSON.stringify(data));
        } catch (error) {
        }
    };

    const handleUpdate = async () => {
        setLoading(true)
        try {
            const { data } = await apiClient.put("/user/renameUser", {
                newName: newName
            });
            setLoading(false)
            getCurrentUser().then();
            handleClose()
        } catch (error) {
            // console.error("Failed to update", error.response.data);
            setLoading(false)
        }
    }

    return (
        <BoxMemeModal sx={{}}>
            <IconButton
                sx={{
                    position: "absolute",
                    backgroundColor: "transparent!important",
                    top: 12,
                    right: 12,
                    p: 0,
                    "& svg": {
                        color: "#ffffff80",
                    },
                }}
                onClick={handleClose}
                size="large"
            >
                <Close fontSize="medium"/>
            </IconButton>

                <Stack sx={{gap: 1}}>
                    <Typography pb={2} textAlign={'center'} fontSize={25} fontWeight={'bold'} color={'#f6f6f6'}>
                        Your profile
                    </Typography>
                    <Typography color={"#fff"}>
                        Name
                    </Typography>
                    <DefaultInput
                        className="number-input"
                        placeholder="Name"
                        variant="outlined"
                        name="name"
                        type={'text'}
                        autoComplete="off"
                        value={newName}
                        onChange={handleEditName}
                        required
                        disabled={!isEdit}
                    />
                    {!isEdit ?
                        <>
                            <Typography color={"#fff"}>
                                Phone number
                            </Typography>
                            <DefaultInput
                                className="disabled-input"
                                variant="outlined"
                                type={'text'}
                                value={currentUser.username}
                                disabled
                            />
                        </> : null}
                </Stack>
                <Stack pt={4} alignItems={'center'}>
                    {!isEdit?
                    <LoadingButton
                        onClick={() => setIsEdit(true)}
                        variant="contained"
                        fullWidth
                        sx={{
                            width: 200,
                            backgroundColor: '#164863',
                            height: 50,
                            borderRadius: '30px',
                            textTransform: 'initial',
                            fontSize: 16, fontWeight: 'bold',
                            "&.Mui-disabled": {
                                cursor: "not-allowed",
                                background: "rgba(45,143,197,0.2)",
                            },
                        }}
                    >
                        Edit profile
                    </LoadingButton>
                        :
                    <LoadingButton
                        onClick={handleUpdate}
                        variant="contained"
                        fullWidth
                        sx={{
                            width: 200,
                            backgroundColor: '#1b6316',
                            height: 50,
                            borderRadius: '30px',
                            textTransform: 'initial',
                            fontSize: 16, fontWeight: 'bold',
                        }}
                    >
                        Update profile
                    </LoadingButton>
                    }
                </Stack>
        </BoxMemeModal>
    )
}
