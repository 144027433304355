import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Backdrop, Box, IconButton, Modal} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import UserProfile from "./UserProfile";
import UserSetting from "./UserSetting";

export default function UserAction() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openProfile, setOpenProfile] = React.useState(false);
    const [openSetting, setOpenSetting] = React.useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenProfile = () => {
        handleClose();
        setOpenProfile(true);
    };

    const handleOpenSetting = () => {
        handleClose();
        setOpenSetting(true);
    };

    const handleCloseProfile = () => {
        setOpenProfile(false);
    };

    const handleCloseSetting = () => {
        setOpenSetting(false);
    };

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/login";
    }

    return (
        <div>
            <Box onClick={handleClick}>
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <MoreVert className="sidebar__action-icon"/></IconButton>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{".MuiList-root": {
                        color: "#fff",
                        paddingX:2,
                        ".MuiMenuItem-root":{
                        }
                    }
                }}
            >
                <MenuItem onClick={handleOpenProfile}>Profile</MenuItem>
                <MenuItem sx={{borderTop:1, borderBottom:1, borderColor:'#ffffff33'}} onClick={handleOpenSetting}>Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openProfile}
                onClose={handleCloseProfile}
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <UserProfile handleClose={handleCloseProfile}/>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openSetting}
                onClose={handleCloseSetting}
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <UserSetting handleClose={handleCloseSetting}/>
            </Modal>

        </div>
    );
}
