import {Backdrop, Box, Checkbox, IconButton, ListItemText, Modal, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Close, GroupAddOutlined} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {DefaultInput} from "../../../components/common/CustomTextField";
import {useNavigate} from "react-router-dom";
import {BoxMemeModal} from "../../../components/common/CustomBox";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import apiClient from "../../../api/apiClient";

// const phoneList = [
//     '09123456789',
//     '07467381763',
//     '03467381763',
//     '09458381563',
//     '05469365763',
//     '01844381763',
//     '08645381763',
//     '02476881763',
// ];

export default function PopupNewGroupChat({ getAllChats }) {

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [users, setUsers] = useState([]);
    const [showCreateGroupChat, setShowCreateGroupChat] = useState(false);
    const [formData, setFormData] = useState({
        groupName: '',
        phoneNumbers: [],
    });

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [open, setOpen] = React.useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [names, setNames] = useState([]);
    const [phoneList, setPhoneList] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getAllUsers = async () => {
        try {
            const {data} = await apiClient.get("/user/allUsers");
            // console.log("Fetched user", data);
            setUsers(data);
            setPhoneList(data.map(user => user.username));
            setNames(data.map(user => user.name || user.username));
            // console.log("Fetched user", data);
            // console.log("Fetched user", phoneNumbers);
        } catch (error) {
            // console.error("Failed to fetch chats", error.response);
        }
    };

    useEffect(() => {
        if (showCreateGroupChat) {
            getAllUsers().then();
        }
    }, [showCreateGroupChat]);

    const handleChangePhoneList = (event) => {
        const {
            target: {value},
        } = event;
        setPhoneNumbers(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleClose = () => {
        setShowCreateGroupChat(false)
        setPhoneNumbers([])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('phoneNumbers', phoneNumbers);
        const userIds = phoneNumbers.map(phoneNumber => users.find(user => user.username === phoneNumber)?._id);
        if (!userIds.includes(currentUser._id)) {
            userIds.push(currentUser._id);
        }
        // console.log('userIds', userIds);

        const groupData = {
            isGroupChat: true,
            participants: userIds,
            groupInfo: {
                name: formData.groupName,
                description: "only me"
            }
        };
        // console.log('!!! groupData', groupData);


        try {
            const response = await apiClient.post('/chats/', groupData, {
                headers: {
                }
            });
            // console.log('Group created successfully:', response.data);

            getAllChats();
            handleClose();
            setFormData({
                groupName: '',
                phoneNumbers: [],
            })
            navigate(`/chat/${response.data._id}`);
        } catch (error) {
            // console.error('Failed to create group chat:', error);
        }

    };


    return (
        <Box>
            <IconButton onClick={()=>setShowCreateGroupChat(true)}>
                <GroupAddOutlined id="new_chat" className="sidebar__action-icon"/>
            </IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showCreateGroupChat}
                onClose={handleClose}

                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <BoxMemeModal>
                    <IconButton
                        sx={{
                            position: "absolute",
                            backgroundColor: "transparent!important",
                            top: 12,
                            right: 12,
                            p: 0,
                            "& svg": {
                                color: "#ffffff80",
                            },
                        }}
                        // disabled={loading}
                        onClick={handleClose}
                        size="large"
                    >
                        <Close fontSize="medium"/>
                    </IconButton>

                    <Box>
                        <form onSubmit={handleSubmit}>
                            <Stack sx={{gap:2, alignItems: 'center'}}>
                                <Typography fontSize={25} fontWeight={'bold'} color={'#f6f6f6'}>
                                    Create a group chat
                                </Typography>
                                <DefaultInput
                                    className="number-input"
                                    placeholder="Group name"
                                    variant="outlined"
                                    name="groupName"
                                    type={'text'}
                                    autoComplete="off"
                                    value={formData.groupName}
                                    onChange={handleChange}
                                    required
                                />

                                <div>
                                    <FormControl sx={{width: 334}}>
                                        <Select className={'mini-scroll'}
                                            multiple
                                            displayEmpty
                                            value={phoneNumbers}
                                            onChange={handleChangePhoneList}
                                            input={<OutlinedInput/>}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <em style={{color: '#ffffff66'}}>Phone numbers</em>;
                                                }

                                                return selected.join(', ');
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{'aria-label': 'Without label'}}
                                            sx={{
                                                height: 50,
                                                padding: 0, margin: 0,
                                                borderRadius: "30px",
                                                color: '#fff',
                                                backgroundColor: '#ffffff33',
                                                "&:hover, &:hover fieldset": {
                                                    // borderColor: "#AD59FB !important",
                                                },
                                                "& .MuiSelect-icon": {
                                                    color: "#FFF",
                                                },
                                            }}
                                        >
                                            {phoneList?.map((name) => (
                                                <MenuItem key={name} value={name} >
                                                    <Checkbox checked={phoneNumbers.includes(name)}/>
                                                    <ListItemText primary={`${name}`} />                                                </MenuItem>
                                                    // <ListItemText primary={`${name} - ${users.find(user => user.username === name)?.name || 'N/A'}`} />                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <Stack alignItems={'center'}
                                    // onClick={handleSubmit}
                                >
                                    <LoadingButton
                                        // loading={loading}
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            backgroundColor: '#164863',
                                            height: 50,
                                            borderRadius: '30px',
                                            textTransform: 'initial',
                                            fontSize: 16, fontWeight: 'bold',
                                            "&.Mui-disabled": {
                                                cursor: "not-allowed",
                                                background: "rgba(45,143,197,0.2)",
                                            },
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Create group chat
                                    </LoadingButton>
                                </Stack>

                            </Stack>
                        </form>
                    </Box>
                </BoxMemeModal>
            </Modal>
        </Box>
    )
}
