// const config = {
//   apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/api',
//   uploadEndpoint: process.env.REACT_APP_UPLOAD_ENDPOINT || 'http://localhost:3000',
//   wsEndpoint: process.env.REACT_APP_WS_ENDPOINT || 'http://localhost:3000',
// };

const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'https://server.chatify.click/api',
  uploadEndpoint: process.env.REACT_APP_UPLOAD_ENDPOINT || 'https://server.chatify.click',
  wsEndpoint: process.env.REACT_APP_WS_ENDPOINT || 'https://server.chatify.click',
};

export default config;