import {IconButton, Stack, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import React from "react";
import {BoxMemeModal} from "../../../components/common/CustomBox";
import {DefaultInput} from "../../../components/common/CustomTextField";

export default function FriendProfile({handleClose, chat}) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [isEdit, setIsEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [newName, setNewName] = React.useState(currentUser.name);

    return (
        <BoxMemeModal>
            <IconButton
                sx={{
                    position: "absolute",
                    backgroundColor: "transparent!important",
                    top: 12,
                    right: 12,
                    p: 0,
                    "& svg": {
                        color: "#ffffff80",
                    },
                }}
                onClick={handleClose}
                size="large"
            >
                <Close fontSize="medium"/>
            </IconButton>

            <Stack sx={{gap: 1}}>
                <Stack>
                    <Typography sx={{textAlign: 'center'}} fontSize={25} fontWeight={'bold'} color={'#f6f6f6'}>
                        {chat.name}
                    </Typography>
                    <Typography pb={2} sx={{textAlign: 'center'}} fontSize={20} color={'#f6f6f6'}>
                        contact info
                    </Typography>
                </Stack>
                <Typography color={"#fff"}>
                    Phone number
                </Typography>
                <DefaultInput
                    variant="outlined"
                    type={'text'}
                    value={chat?.phone}
                    disabled
                />
            </Stack>
        </BoxMemeModal>
    )
}
