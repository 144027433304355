import { TextField, styled } from "@mui/material";

export const DefaultInput = styled(TextField)(({ theme }) => ({
    width: "100%",
    color: "#fff",
    "svg":{color:'#fff', padding:'2px'},
    "& input": {
        padding: "0 20px", height:50,
    },
    "& .MuiSelect-select": {
        display: "inline-flex",
    },
    "& label.Mui-focused": {
        color: "#fff8",
    },
    "& .MuiOutlinedInput-root": {
        color: "#fff",
        "& fieldset": {
            borderRadius:'30px',
            backgroundColor:'#ffffff33'
        },
    },
    "& > label": {
        color: "white!important",
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            color: "#fff",
        },
    },
    "& .Mui-disabled": {
        "-webkit-text-fill-color": "#fff !important", // Override default webkit styling
        opacity: "1 !important", // Ensure opacity doesn't affect visibility
        cursor:'not-allowed'
    },
}));