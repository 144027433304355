import React, {useEffect, useState} from "react";
import "./styles/main.css";
import Icon from "../../components/Icon";
import Contact from "./Contact";
import OptionsBtn from "../../components/OptionsButton";
import apiClient from "../../api/apiClient";
import PopupNewChat from "../../pages/Chat/components/PopupNewChat";
import PopupNewGroupChat from "../../pages/Chat/components/PopupNewGroupChat";
import userAvatarDefaultAvatar from "../../assets/images/user-avatar-default.webp";
import {useNavigate} from "react-router-dom";
import {Image, MoreVert} from "@mui/icons-material";
import {Avatar, Box, IconButton} from "@mui/material";
import UserAction from "../user/UserAction";

const Sidebar = () => {
	const [contacts, setContacts] = useState([]);
	const navigate = useNavigate();
	let avatar = userAvatarDefaultAvatar;
	const currentUser = JSON.parse(localStorage.getItem("currentUser"));
	if (currentUser && currentUser.profileImage) {
		if (currentUser.profileImage !== "default.svg") {
			avatar = currentUser.profileImage;
		}
	}

	const getAllChats = async () => {
		try {
			const {data} = await apiClient.get("/chats/");
			// console.log("Fetched chats", data);
			data.sort((a, b) => {
				const dateA = a.lastMessage ? new Date(a.lastMessage.sentAt) : new Date(a.createdAt);
				const dateB = b.lastMessage ? new Date(b.lastMessage.sentAt) : new Date(b.createdAt);
				return dateB - dateA;
			});
			setContacts(data);
		} catch (error) {
			console.error("Failed to fetch chats", error.response.data);
		}
	};

	useEffect(() => {
		getAllChats().then();
	}, []);

	const handleOptionClick = (option) => {
		if (option === "Log out") {
			// console.log("Logging out");
			localStorage.clear();
			navigate("/login");
		}
	};

	return (
		<aside className="sidebar">
			<header className="header">
				<div className="sidebar__avatar-wrapper">
					<Avatar src={avatar} alt="Avatar" width={40} height={40}/>
				</div>
				<div className="sidebar__actions">
					<PopupNewChat getAllChats={getAllChats}/>
					<PopupNewGroupChat getAllChats={getAllChats}/>
					{/*<OptionsBtn*/}
					{/*	className="sidebar__action"*/}
					{/*	ariaLabel="Menu"*/}
					{/*	iconId="menu"*/}
					{/*	iconClassName="sidebar__action-icon"*/}
					{/*	options={[*/}
					{/*		"Profile",*/}
					{/*		"Settings",*/}
					{/*		"Log out",*/}
					{/*	]}*/}
					{/*	onOptionClick={handleOptionClick}*/}
					{/*/>*/}
					<UserAction/>
				</div>
			</header>
			{/*<Alert />*/}
			<div className="search-wrapper">
				<div className="search-icons">
					<Icon id="search" className="search-icon"/>
					<button className="search__back-btn">
						<Icon id="back"/>
					</button>
				</div>
				<input className="search" placeholder="Search or start a new chat"/>
			</div>
			<div className="sidebar__contacts">
				{contacts.map((contact, index) => (
					<Contact key={index} contact={contact}/>
				))}
			</div>
		</aside>
	);
};

export default Sidebar;
