import React, {useEffect, useRef, useState} from "react";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import Header from "./components/Header";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "../../components/Icon";
import Search from "./components/Search";
import Convo from "./components/Convo";
import Sidebar from "../../components/Sidebar";
import io from "socket.io-client";
import apiClient from "../../api/apiClient";
import groupDefaultAvatar from "../../assets/images/group-default.png";
import userDefaultAvatar from "../../assets/images/user-default.svg";
import config from "../../config";
import {useParams} from "react-router-dom";


const Chat = ({history}) => {
    // const {users, setUserAsUnread, addNewMessage} = useUsersContext();
    const { id } = useParams();

    useEffect(() => {
        // console.log("Chat ID changed:", id);
        setTimeout(() => {
            scrollToLastMsg();
        }, 1000);
    }, [id]);


    const [messages, setMessages] = useState([]);
    const [roomId, setRoomId] = useState(null);
    const [chat, setChat] = useState({
        id: "",
        name: "",
        phone: "",
        avatar: "",
        isGroupChat: false
    })

    const [participants, setParticipants] = useState([]);
    const participantsRef = useRef([]);

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));


    // const { id } = useParams();

    // useEffect(() => {
    //     // Your logic to handle the change in chat ID
    //     console.log(`Chat ID changed to: ${id}`);
    //     // Fetch new chat data or perform any other necessary actions
    // }, [id]);

    let chatId = window.location.pathname.split("/")[2];
    // console.log("Chat id", chatId);

    const lastMsgRef = useRef(null);
    const [showAttach, setShowAttach] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [showProfileSidebar, setShowProfileSidebar] = useState(false);
    const [showSearchSidebar, setShowSearchSidebar] = useState(false);
    const [newMessage, setNewMessage] = useState("");

    useEffect(() => {
        if (chatId) {
            getChatById(chatId).then(() => {
                fetchMessages(chatId).then(r => {
                    // scrollToLastMsg();
                });
            });
        }
    }, [chatId]);

    const fetchMessages = async (chatId) => {
        // console.log("!!!Fetching messages for chat", chatId);
        try {
            const response = await apiClient.get(
                `/messages/?chatId=${chatId}&beforeTime=${encodeURIComponent(
                    new Date().toISOString()
                )}&limit=300`,
                {
                }
            );

            const fetchedMessages = response.data;

            fetchedMessages.reverse();

            // console.log("Fetched participantsRef.current", participantsRef.current);

            fetchedMessages.forEach((msg) => {
                const senderId = msg.senderId._id;
                const sender = participantsRef.current.find(participant => participant._id === senderId);
                if (sender) {
                    msg.senderId = {
                        _id: senderId,
                        name: sender.name,
                    };
                }
            });

            // console.log("Fetched messages", fetchedMessages);


            setMessages([]);
            setMessages((prevMessages) => {
                // console.log("=== Previous messages:", prevMessages);
                const updatedMessages = Array.isArray(prevMessages) ? [...fetchedMessages, ...prevMessages] : [...fetchedMessages];
                // console.log("==== Updated messages:", updatedMessages);
                return updatedMessages;
            });

            scrollToLastMsg();
        } catch (error) {
            // console.error("Error fetching messages:", error);
        }
    };

    const socketRef = useRef(null);

    useEffect(() => {
        const socket = io(config.wsEndpoint);
        socketRef.current = socket;

        socket.on("connect", () => {
            console.log("Socket connected:", socket.id);
        });

        socket.on("messageReceived", (msg) => {
            // console.log("Message received:", msg);
            const senderId = msg.senderId;
            //get sender name from participants

            // console.log("Chat participants", participantsRef.current);
            const sender = participantsRef.current.find(participant => participant._id === senderId);
            msg.senderId = {
                _id: senderId,
                name: sender.name,
            };
            const newMessage = msg;
            // appendMessage(newMessage);
            setMessages((prev) => Array.isArray(prev) ? [...prev, msg] : [msg]);
            if (msg.type === "text") {
                scrollToLastMsg();
            } else {
                setTimeout(() => {
                    scrollToLastMsg();
                }, 1000);
            }
        });

        socket.on("disconnect", () => {
            // console.log("Socket disconnected");
        });

        return () => {
            socket.disconnect();
            socketRef.current = null;
        };
    }, []);

    useEffect(() => {
        if (roomId && socketRef.current) {
            // console.log("Joining room", roomId);
            socketRef.current.emit("join", { userId: currentUser._id, chatId: roomId });
        }
    }, [roomId]);

    useEffect(() => {
        participantsRef.current = participants;
    }, [participants]);

    const sendMessage = (messageType = 'text', fileUrl = '') => {
        // console.log("Sending message messageType", messageType);
        // console.log("Sending message", newMessage);
        if (newMessage.trim() && socketRef.current) {
            const messagePayload = {
                chatId: roomId,
                content: newMessage,
                senderId: currentUser._id,
                type: messageType,
                sentAt: new Date(),
            };
            // console.log("Sending message payload", messagePayload);
            socketRef.current.emit("sendMessage", messagePayload);
            setNewMessage("");
            scrollToLastMsg();
        } else if (fileUrl && socketRef.current) {
            const messagePayload = {
                chatId: roomId,
                content: fileUrl,
                senderId: currentUser._id,
                type: messageType,
                sentAt: new Date(),
            };
            // console.log("Sending message file", messagePayload);
            socketRef.current.emit("sendMessage", messagePayload);
            setNewMessage("");
            scrollToLastMsg();
        }
    };


    const getChatById = async (id) => {
        // console.log("Fetching chat", id);
        try {
            const {data} = await apiClient.get(`/chats/${id}`);
            // console.log("Fetched chat", data);

            const isGroupChat = data.isGroupChat;
            const chatName = isGroupChat
              ? data.groupInfo.name
              : data.participants.find(participant => participant._id !== currentUser._id).name || data.participants.find(participant => participant._id !== currentUser._id).username;

            const phone = isGroupChat
              ? 0
              : data.participants.find(participant => participant._id !== currentUser._id).username;

            let chatAvatar = isGroupChat ? groupDefaultAvatar : data.participants.find(participant => participant._id !== currentUser._id).profileImage;

            if (!chatAvatar || chatAvatar === 'default.svg') {
                chatAvatar = userDefaultAvatar;
            }

            const chat = {
                id: data._id,
                name: chatName,
                phone: phone,
                avatar: chatAvatar,
                isGroupChat: isGroupChat
            }
            // console.log("setChat", chat);
            setChat(chat);
            setParticipants(data.participants);

            setMessages(data.messages);
            setRoomId(data._id);

        } catch (error) {
            console.error("Failed to fetch chat", error);
        }
    };

    const openSidebar = (cb) => {
        // close any open sidebar first
        setShowProfileSidebar(false);
        setShowSearchSidebar(false);

        // call callback fn
        cb(true);
    };

    const scrollToLastMsg = () => {
        lastMsgRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const submitNewMessage = (messageType = 'text', fileUrl = '') => {
        // console.log("submitNewMessage", newMessage);
        // addNewMessage(user.id, newMessage);
        // setNewMessage("");
        // scrollToLastMsg();
        sendMessage(messageType, fileUrl);
    };

    return (
        <div className="app-content">
            <Sidebar/>
            <div className="chat">
                <div className="chat__body">
                    <div className="chat__bg"></div>

                    <Header
                        chat={chat}
                        openProfileSidebar={() => openSidebar(setShowProfileSidebar)}
                        openSearchSidebar={() => openSidebar(setShowSearchSidebar)}
                    />
                    <div className="chat__content">
                        <Convo participants={participants} isChatGroup={chat.isGroupChat} lastMsgRef={lastMsgRef} messages={messages}/>
                    </div>
                    <footer className="chat__footer">
                        <button
                            className="chat__scroll-btn"
                            aria-label="scroll down"
                            onClick={scrollToLastMsg}
                        >
                            <Icon id="downArrow"/>
                        </button>
                        <EmojiTray
                            showEmojis={showEmojis}
                            newMessage={newMessage}
                            setNewMessage={setNewMessage}
                        />
                        <ChatInput
                            showEmojis={showEmojis}
                            setShowEmojis={setShowEmojis}
                            showAttach={showAttach}
                            setShowAttach={setShowAttach}
                            newMessage={newMessage}
                            setNewMessage={setNewMessage}
                            submitNewMessage={submitNewMessage}
                        />
                    </footer>
                </div>
                <ChatSidebar
                    heading="Search Messages"
                    active={showSearchSidebar}
                    closeSidebar={() => setShowSearchSidebar(false)}
                >
                    <Search/>
                </ChatSidebar>

                <ChatSidebar
                    heading="Contact Info"
                    active={showProfileSidebar}
                    closeSidebar={() => setShowProfileSidebar(false)}
                >
                    {/*//TODO: Implement Profile component*/}
                    {/*<Profile user={user}/>*/}
                </ChatSidebar>
            </div>
        </div>
    );
};

export default Chat;
