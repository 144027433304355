import React, {useState} from 'react';
import axios from 'axios';
import {Typography, Stack, InputAdornment, IconButton, Snackbar} from '@mui/material';
import {DefaultInput} from "../../components/common/CustomTextField";
import {Link} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import apiClient from "../../api/apiClient";

const Index = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        rePassword: '',
    });

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = useState(true);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.rePassword) {
            setError('Password does not match');
            setOpen(true);
            return;
        }
        setLoading(true)
        try {
            const { data }  = await apiClient.post("/auth/register", {
                username: formData.username,
                password: formData.password,
            });
            setLoading(false)
            navigate('/login')
        } catch (err) {
            const {response} = err;
            setError(response?.data?.message || 'Register failed!');
            setOpen(true);
            setLoading(false)
        }
    };

    return (
        <Stack sx={{height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="h2" fontWeight={'bold'} color={'deepskyblue'} mb={3}>
                Talo
            </Typography>
            <Stack sx={{gap: 3, width: {xs:'80vw', md: 400}, padding:3, borderRadius:5, backgroundColor:'rgba(255,255,255,0.05)'}}>
                <Stack sx={{alignItems: 'center'}}>
                    <Typography fontSize={25} fontWeight={'bold'} color={'#f6f6f6'}>
                        Create a new account
                    </Typography>
                    <Typography color={'#f6f6f6'}>
                        It's quick and easy.
                    </Typography>
                </Stack>

                <form onSubmit={handleSubmit}>
                    <Stack sx={{gap: 3, alignItems: 'center'}}>
                        <DefaultInput
                            className="number-input"
                            placeholder="Phone number"
                            variant="outlined"
                            name="username"
                            type={'number'}
                            autoComplete="off"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />

                        <DefaultInput
                            placeholder="New password"
                            variant="outlined"
                            name="password"
                            autoComplete="off"
                            type={showPassword ? 'password':'text'}
                            value={formData.password}
                            onChange={handleChange}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <DefaultInput
                            placeholder="Confirm password"
                            variant="outlined"
                            name="rePassword"
                            autoComplete="off"
                            type={showPassword ? 'password':'text'}
                            value={formData.rePassword}
                            onChange={handleChange}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={formData.rePassword && formData.password !== formData.rePassword}
                        />

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            fullWidth
                            loading={loading}
                            sx={{
                                backgroundColor: '#164863',
                                height: 50,
                                borderRadius: '30px',
                                textTransform: 'initial',
                                fontSize: 16, fontWeight: 'bold',
                                "&.Mui-disabled": {
                                    cursor: "not-allowed",
                                    background: "rgba(45,143,197,0.2)",
                                },
                            }}
                        >
                            Sign Up
                        </LoadingButton>

                        <Link to={'/login'}>
                            Already have an account?
                        </Link>
                    </Stack>
                </form>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    TransitionComponent={'up'}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert
                        onClose={() => setOpen(false)}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            </Stack>
        </Stack>
    );
};

export default Index;
