import React, {useRef, useState} from "react";
import Icon from "../../../components/Icon";
import {Navigation} from "@mui/icons-material";
import axios from "axios";
import config from "../../../config";

// const attachButtons = [
// 	// { icon: "attachRooms", label: "Choose room" },
// 	// { icon: "attachContacts", label: "Choose contact" },
// 	{ icon: "attachDocument", label: "Choose document" },
// 	{ icon: "attachCamera", label: "Choose video" },
// 	{ icon: "attachImage", label: "Choose image" },
// ];

const attachButtons = [
	{ icon: "attachDocument", label: "Choose document", type: "file" },
	{ icon: "attachCamera", label: "Choose video", type: "video" },
	{ icon: "attachImage", label: "Choose image", type: "image" },
];

const ChatInput = ({
	showAttach,
	setShowAttach,
	showEmojis,
	setShowEmojis,
	newMessage,
	setNewMessage,
	submitNewMessage,
}) => {

	const detectEnterPress = (e) => {
		console.log("detectEnterPress:", e.key, e.keyCode);
		if (e.key === "Enter" && e.keyCode === 13) {
			submitNewMessage('text');
		}
	};

	const fileInputRef = useRef(null);
	const [fileType, setFileType] = useState("");

	const handleFileChange = async (e) => {
		const file = e.target.files[0];
		// console.log("handleFileChange:", file);

		if (file) {
			// console.log("File selected:", file);
			const fileUrl = await uploadFile(file);
			if (fileUrl) {
				// console.log("!!!File fileUrl:", fileUrl);
				submitNewMessage(fileType, fileUrl);
				setShowAttach(false);
			}
		}
	};

	const uploadFile = async (file) => {
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post(`${config.uploadEndpoint}/upload`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			// console.log("File uploaded:", response.data);
			return response.data.fileUrl;
		} catch (error) {
			// console.error("Error uploading file:", error);
			alert("Error uploading file.");
			return null;
		}
	};

	return (
		<div className="chat__input-wrapper">
			{showEmojis && (
				<button aria-label="Close emojis" onClick={() => setShowEmojis(false)}>
					<Icon id="cancel" className="chat__input-icon"/>
				</button>
			)}
			<button aria-label="Emojis" onClick={() => setShowEmojis(true)}>
				<Icon
					id="smiley"
					className={`chat__input-icon ${
						showEmojis ? "chat__input-icon--highlight" : ""
					}`}
				/>
			</button>
			{showEmojis && (
				<>
					{/*<button aria-label="Choose GIF">*/}
					{/*	<Icon id="gif" className="chat__input-icon"/>*/}
					{/*</button>*/}
					{/*<button aria-label="Choose sticker">*/}
					{/*	<Icon id="sticker" className="chat__input-icon"/>*/}
					{/*</button>*/}
				</>
			)}
			<div className="pos-rel">
				<button aria-label="Attach" onClick={() => setShowAttach(!showAttach)}>
					<Icon
						id="attach"
						className={`chat__input-icon ${
							showAttach ? "chat__input-icon--pressed" : ""
						}`}
					/>
				</button>

				<div
					className={`chat__attach ${showAttach ? "chat__attach--active" : ""}`}
				>
					{attachButtons.map((btn) => (
						<button
							className="chat__attach-btn"
							aria-label={btn.label}
							key={btn.label}
							onClick={() => {
								// console.log("Attach button clicked:", btn.label);
								// console.log(fileInputRef.current);
								setFileType(btn.type);
								fileInputRef.current.click()
							}
						}
						>
							<Icon id={btn.icon} className="chat__attach-icon"/>
						</button>
					))}
				</div>
			</div>
			<input
				className="chat__input"
				placeholder="Type a message"
				value={newMessage}
				onChange={(e) => setNewMessage(e.target.value)}
				onKeyDown={detectEnterPress}
			/>
			{newMessage ? (
				<button aria-label="Send message" onClick={() => submitNewMessage('text')}>
					<Navigation sx={{fontSize: '30px'}} id="send" className="chat__input-icon"/>
				</button>
			) : null
				// <button aria-label="Record voice note">
				// 	<Icon id="microphone" className="chat__input-icon" />
				// </button>
			}

			<input
				type="file"
				ref={fileInputRef}
				style={{display: "none"}}
				onChange={handleFileChange}
			/>
		</div>
	);
};

export default ChatInput;
