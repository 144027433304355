import { format } from "date-fns";

const formatTime = (timeString) => {
	let splitTimeString = timeString.split(":");
	return `${splitTimeString[0]}:${splitTimeString[1]}`;
};

export const formatTimeMessage = (timeString, excludeYear = false) => {
	const date = new Date(timeString);
	const today = new Date();

	const isToday = date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear();

	if (isToday) {
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	} else {
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		if (excludeYear) {
			return `${day}/${month}`;
		} else {
			return `${day}/${month}/${year}`;
		}
	}
};

export const formatDateHeader = (date) => {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	if (date.toDateString() === today.toDateString()) {
		return "Today";
	} else if (date.toDateString() === yesterday.toDateString()) {
		return "Yesterday";
	} else {
		return format(date, "dd/MM/yyyy");
	}
};

export default formatTime;

