import React from "react";
import {Link} from "react-router-dom";
import formatTime, {formatTimeMessage} from "../../utils/formatTime";
import Icon from "../Icon";
import {useUsersContext} from "../../context/usersContext";
import groupDefaultAvatar from "../../assets/images/group-default.png";
import userDefaultAvatar from "../../assets/images/user-default.svg";
import {Avatar, AvatarGroup, Grid, Stack} from "@mui/material";

const Contact = ({contact}) => {
    const {setUserAsUnread} = useUsersContext();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const getLastMessage = () => {
        const {lastMessage} = contact;
        return lastMessage;
    };

    let lastMessage = getLastMessage(contact);
    if (!lastMessage) {
        lastMessage = {
            chatId: contact._id,
            type: "text",
            content: "New Chat",
            sentAt: contact.createdAt,
        }
    }

    const isGroupChat = contact.isGroupChat;
    const chatName = isGroupChat
        ? contact.groupInfo.name
        : contact.participants.find(participant => participant._id !== currentUser._id).name || contact.participants.find(participant => participant._id !== currentUser._id).username;
    let chatAvatar = isGroupChat ? groupDefaultAvatar : contact.participants.find(participant => participant._id !== currentUser._id).profileImage;

    if (!chatAvatar || chatAvatar === 'default.svg') {
        chatAvatar = userDefaultAvatar;
    }

    return (
        <Link
            className="sidebar-contact"
            to={`/chat/${contact._id}`}
            // onClick={() => setUserAsUnread(contact.id)}
        >
            <div className="sidebar-contact__avatar-wrapper">
                <AvatarGroup
                    sx={{
                        "& .MuiAvatar-root": {
                            fontSize: 10,
                            height: contact.participants.length > 2 ? 20 : 40,
                            width: contact.participants.length > 2 ? 20 : 40
                        }
                    }}
                    spacing={10} className="sidebar-contact__avatar-group">
                    {contact.participants
                        .filter(participant => participant._id !== currentUser._id)
                        .map((participant, index) => {
                            if (index < 2) {
                                return (
                                    <Avatar
                                        key={participant._id}
                                        src={participant.profileImage || userDefaultAvatar}
                                        alt={participant.name}
                                        // className="sidebar-contact__avatar"
                                    />
                                );
                            }
                            return null;
                        })}

                </AvatarGroup>
                {contact.participants.length > 2 ?
                    <AvatarGroup
                        sx={{"& .MuiAvatar-root": {height: 20, width: 20, fontSize: 10}}}
                        spacing={10} total={contact.participants.length - 2} className="sidebar-contact__avatar-group">
                        {contact.participants
                            .filter(participant => participant._id !== currentUser._id)
                            .map((participant, index) => {
                                if (index > 1 && index < 3) {
                                    return (
                                        <Avatar
                                            width={10} height={10}
                                            key={participant._id}
                                            src={participant.profileImage || userDefaultAvatar}
                                            alt={participant.name}
                                            // className="sidebar-contact__avatar"
                                        />
                                    );
                                }
                                return null;
                            })}

                    </AvatarGroup>
                    : null}
            </div>
            <div className="sidebar-contact__content">
                <div className="sidebar-contact__top-content">
                    <h2 className="sidebar-contact__name"> {chatName} </h2>
                    <span className="sidebar-contact__time">
						{formatTimeMessage(lastMessage?.sentAt)}
					</span>
                </div>
                <div className="sidebar-contact__bottom-content">
                    <p className="sidebar-contact__message-wrapper">
                        {(
                            <Icon
                                id={
                                    "singleTick"
                                }
                                className={`sidebar-contact__message-icon`}
                            />
                            // <Icon
                            // 	id={
                            // 		lastMessage?.status === "sent" ? "singleTick" : "doubleTick"
                            // 	}
                            // 	aria-label={lastMessage?.status}
                            // 	className={`sidebar-contact__message-icon ${
                            // 		lastMessage?.status === "read"
                            // 			? "sidebar-contact__message-icon--blue"
                            // 			: ""
                            // 	}`}
                            // />
                        )}
                        <span
                            className={`sidebar-contact__message ${
                                !!contact.unread ? "sidebar-contact__message--unread" : ""
                            }`}
                        >
							{lastMessage.type === 'text' ? lastMessage?.content : 'Media 📁'}
						</span>
                    </p>
                    {/*
                    <div className="sidebar-contact__icons">
                        {contact.pinned && (
                            <Icon id="pinned" className="sidebar-contact__icon"/>
                        )}
                        {!!contact.unread && (
                            <span className="sidebar-contact__unread">{contact.unread}</span>
                        )}
                        <button aria-label="sidebar-contact__btn">
                            <Icon
                                id="downArrow"
                                className="sidebar-contact__icon sidebar-contact__icon--dropdown"
                            />
                        </button>
                    </div>
                    */}
                </div>
            </div>
        </Link>
    );
};

export default Contact;
