import {BoxMemeModal} from "../common/CustomBox";
import {IconButton, InputAdornment, Snackbar, Stack, Typography} from "@mui/material";
import {DefaultInput} from "../common/CustomTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {Close, Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useState} from "react";
import apiClient from "../../api/apiClient";
import {Alert} from "@mui/lab";


export default function UserSetting({handleClose}) {
    const [showPassword, setShowPassword] = useState(true);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleUpdate = async () => {
        setLoading(true)
        try {
            const {data} = await apiClient.put("/user/passwordUpdate", {
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword
            });
            setLoading(false)
            if(data?.status === false) {
                setError("Invalid old password")
                setOpen(true)
            } else {
                setError("Password updated successfully")
                setOpen(true)
                formData.oldPassword = ''
                formData.newPassword = ''
                setShowPassword(true)
            }
        } catch (error) {
            // console.error("Failed to update", error.response.data);
            setLoading(false)
        }
    }

    return (
        <>
            <BoxMemeModal sx={{}}>
                <IconButton
                    sx={{
                        position: "absolute",
                        backgroundColor: "transparent!important",
                        top: 12,
                        right: 12,
                        p: 0,
                        "& svg": {
                            color: "#ffffff80",
                        },
                    }}
                    onClick={handleClose}
                    size="large"
                >
                    <Close fontSize="medium"/>
                </IconButton>

                <Stack sx={{gap: 1}}>
                    <Typography pb={2} sx={{textAlign:"center"}} fontSize={25} fontWeight={'bold'} color={'#f6f6f6'}>
                        Setting your password
                    </Typography>
                    <Typography color={"#fff"}>
                        Old password
                    </Typography>
                    <DefaultInput
                        placeholder="Old password"
                        variant="outlined"
                        name="oldPassword"
                        autoComplete="off"
                        type={showPassword ? 'password' : 'text'}
                        value={formData.oldPassword}
                        onChange={handleChange}
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Typography color={"#fff"}>
                        New password
                    </Typography>
                    <DefaultInput
                        placeholder="New password"
                        variant="outlined"
                        name="newPassword"
                        autoComplete="off"
                        type={showPassword ? 'password' : 'text'}
                        value={formData.newPassword}
                        onChange={handleChange}
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                </Stack>
                <Stack pt={4} alignItems={'center'}>

                    <LoadingButton
                        onClick={handleUpdate}
                        disabled={loading || !formData.oldPassword || !formData.newPassword}
                        variant="contained"
                        fullWidth
                        loading={loading}
                        sx={{
                            width: 200,
                            backgroundColor: '#1b6316',
                            height: 50,
                            borderRadius: '30px',
                            textTransform: 'initial',
                            fontSize: 16, fontWeight: 'bold',
                            "&.Mui-disabled": {
                                cursor: "not-allowed",
                                background: "#4c4c4c",
                                color:'#d7d7d780'
                            },
                        }}
                    >
                        Update password
                    </LoadingButton>
                </Stack>
            </BoxMemeModal>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="error"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    )
}