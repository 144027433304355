import React from "react";
import Icon from "../../../components/Icon";
import OptionsBtn from "../../../components/OptionsButton";
import {Image} from "@mui/icons-material";
import ChatAction from "./ChatAction";

const Header = ({ chat, openProfileSidebar}) => {
	return (
		<header className="header chat__header">
			<div className="chat__avatar-wrapper" onClick={openProfileSidebar}>
				<img src={chat.avatar} alt={chat.name} className="avatar" />
			</div>

			<div className="chat__contact-wrapper" onClick={openProfileSidebar}>
				<h2 className="chat__contact-name"> {chat.name}</h2>
				{/*<p className="chat__contact-desc">*/}
				{/*	{user.typing ? "typing..." : "online"}*/}
				{/*</p>*/}
			</div>
			<div className="chat__actions">
				{/*
				<button
					className="chat__action"
					aria-label="Search"
					onClick={openSearchSidebar}
				>
					<Icon
						id="search"
						className="chat__action-icon chat__action-icon--search"
					/>
				</button>

				<OptionsBtn
					className="chat__action"
					ariaLabel="Menu"
					iconId="menu"
					iconClassName="chat__action-icon"
					options={[
						"Contact Info",
						"Select Messages",
						"Mute notifications",
						"Clear messages",
						"Delete chat",
					]}
				/>
				*/}
				<ChatAction chat={chat}/>
			</div>
		</header>
	);
};

export default Header;
