import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Backdrop, Box, IconButton, Modal} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import apiClient from "../../../api/apiClient";
import {useNavigate} from "react-router-dom";
import UserProfile from "../../../components/user/UserProfile";
import FriendProfile from "./FriendProfile";

export default function ChatAction({chat}) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openProfile, setOpenProfile] = React.useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenProfile = () => {
        handleClose();
        setOpenProfile(true);
    };

    const handleCloseProfile = () => {
        setOpenProfile(false);
    };

    const getAllChats = async () => {
        try {
            const {data} = await apiClient.get("/chats/");
            // console.log("Fetched chats", data);
            data.sort((a, b) => {
                const dateA = a.lastMessage ? new Date(a.lastMessage.sentAt) : new Date(a.createdAt);
                const dateB = b.lastMessage ? new Date(b.lastMessage.sentAt) : new Date(b.createdAt);
                return dateB - dateA;
            });
        } catch (error) {
            console.error("Failed to fetch chats", error.response.data);
        }
    };

    const handleDeleteChat = async() => {
        try {
            const response = await apiClient.delete(`/chats/${chat.id}`);
            getAllChats().then();
            navigate(`/chat`);
        } catch (error) {
            // console.error('Failed to create group chat:', error);
        }
    }

    return (
        <div>
            <Box onClick={handleClick}>
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <MoreVert className="sidebar__action-icon"/></IconButton>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{".MuiList-root": {
                        color: "#fff",
                        paddingX:2,
                        ".MuiMenuItem-root":{
                        }
                    }
                }}
            >
                {chat.isGroupChat? null:
                <MenuItem onClick={handleOpenProfile}>Contact info</MenuItem>}
                <MenuItem onClick={handleDeleteChat}>Delete chat</MenuItem>
            </Menu>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openProfile}
                onClose={handleCloseProfile}
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <FriendProfile handleClose={handleCloseProfile} chat={chat}/>
            </Modal>
        </div>
    );
}
