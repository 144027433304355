import {Box, styled} from "@mui/material";

export const BoxMemeModal = styled(Box)(({theme}) => ({
    gap: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: "32px 32px",
    height: "fit-content",
    width: "400px",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    backdropFilter: 'blur(10px)',
    borderRadius: "20px",
    border: "1px solid rgba(255,255,255,0.09)",
    overflow: "hidden",
}));