import {Backdrop, Box, Checkbox, IconButton, ListItemText, Modal, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Close, PersonAddAlt1Outlined} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate} from "react-router-dom";
import apiClient from "../../../api/apiClient";
import {BoxMemeModal} from "../../../components/common/CustomBox";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

// const phoneList = [
//     '09123456789',
//     '07467381763',
//     '03467381763',
//     '09458381563',
//     '05469365763',
//     '01844381763',
//     '08645381763',
//     '02476881763',
// ];

export default function PopupNewChat({ getAllChats }) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [users, setUsers] = useState([]);

    const [showCreateChat, setShowCreateChat] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [names, setNames] = useState([]);
    const [phoneList, setPhoneList] = useState([]);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getAllUsers = async () => {
        try {
            const {data} = await apiClient.get("/user/allUsers");
            // console.log("Fetched user single chat", data);
            setUsers(data);
            setPhoneList(data.map(user => user.username));
            setNames(data.map(user => user.name || user.username));
            // console.log("Fetched user", data);
        } catch (error) {
            // console.error("Failed to fetch chats", error.response);
        }
    };

    useEffect(() => {
        if (showCreateChat) {
            getAllUsers().then();
        }
    }, [showCreateChat]);

    const handleClose = () => {
        setShowCreateChat(false)
        setPhoneNumber(null)
    }

    const handleChangePhone = (event) => {
        const {
            target: {value},
        } = event;
        setPhoneNumber(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(phoneNumber[0]);
        // console.log(users);
        const user = users.find(user => user.username === phoneNumber[0]);
        // console.log("user", user);
        // console.log("currentUser id", currentUser._id);
        // console.log([currentUser._id, user._id])

        const groupData = {
            isGroupChat: false,
            participants: [currentUser._id, user._id],
        };
        // console.log('!!! single chat data', groupData);


        try {
            const response = await apiClient.post('/chats/', groupData, {
                headers: {
                }
            });
            // console.log('Single chat created successfully:', response.data);

            getAllChats();
            handleClose();
            setPhoneNumber(null)
            navigate(`/chat/${response.data._id}`);
        } catch (error) {
            // console.error('Failed to create group chat:', error);
        }


    };

    return (
        <Box>
            <IconButton onClick={()=>setShowCreateChat(true)}>
                <PersonAddAlt1Outlined id="new_chat" className="sidebar__action-icon"/>
            </IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showCreateChat}
                onClose={handleClose}

                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <BoxMemeModal>
                    <IconButton
                        sx={{
                            position: "absolute",
                            backgroundColor: "transparent!important",
                            top: 12,
                            right: 12,
                            p: 0,
                            "& svg": {
                                color: "#ffffff80",
                            },
                        }}
                        // disabled={loading}
                        onClick={handleClose}
                        size="large"
                    >
                        <Close fontSize="medium"/>
                    </IconButton>

                    <Box>
                    <form onSubmit={handleSubmit}>
                        <Stack sx={{gap:1, alignItems: 'center'}}>
                            <Typography fontSize={25} mb={2} fontWeight={'bold'} color={'#f6f6f6'}>
                                Create a new chat
                            </Typography>

                            <div>
                                <FormControl sx={{width: 334}}>
                                    <Select className={'mini-scroll'}
                                            displayEmpty
                                            value={phoneNumber}
                                            onChange={handleChangePhone}
                                            input={<OutlinedInput/>}
                                            renderValue={(selected) => {
                                                if (!phoneNumber) {
                                                    return <em style={{color: '#ffffff66'}}>Phone number</em>;
                                                }

                                                return selected;
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{'aria-label': 'Without label'}}
                                            sx={{
                                                height: 50,
                                                padding: 0, margin: 0,
                                                borderRadius: "30px",
                                                color: '#fff',
                                                backgroundColor: '#ffffff33',
                                                "&:hover, &:hover fieldset": {
                                                    // borderColor: "#AD59FB !important",
                                                },
                                                "& .MuiSelect-icon": {
                                                    color: "#FFF",
                                                },
                                            }}
                                    >
                                        {phoneList?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox checked={String(phoneNumber) === String(item)}/>
                                                <ListItemText primary={`${item}`} />
                                                {/*<ListItemText primary={`${item} - ${users.find(user => user.username === item)?.name || 'N/A'}`} />*/}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <Stack pt={{md: 2}} alignItems={'center'}
                                // onClick={handleSubmit}
                            >
                                <LoadingButton
                                    // loading={loading}
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#164863',
                                        height: 50,
                                        borderRadius: '30px',
                                        textTransform: 'initial',
                                        fontSize: 16, fontWeight: 'bold',
                                        "&.Mui-disabled": {
                                            cursor: "not-allowed",
                                            background: "rgba(45,143,197,0.2)",
                                        },
                                    }}
                                >
                                    Create chat
                                </LoadingButton>
                            </Stack>

                        </Stack>
                    </form>
                    </Box>
                </BoxMemeModal>
            </Modal>
        </Box>
    )
}
