import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/css/index.css";
import { UsersProvider } from "./context/usersContext";
import { SocketProvider } from "./context/socketContext";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<React.StrictMode>
		<SocketProvider>
			<UsersProvider>
				<BrowserRouter>
				<App />
				</BrowserRouter>
			</UsersProvider>
		</SocketProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
