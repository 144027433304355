import React, {useEffect} from "react";
import "./styles/main.css";
import introImgLight from "../../assets/images/logo.png";
import introImgDark from "../../assets/images/logo.png";
import Sidebar from "../../components/Sidebar";
import apiClient from "../../api/apiClient";

const Home = () => {
  const darkTheme = document.body.classList.contains("dark-theme");

  const getCurrentUser = async () => {
    try {
      const { data } = await apiClient.get("/user/");
      // console.log("Fetched user", data);
      localStorage.setItem("currentUser", JSON.stringify(data));
    } catch (error) {
      // console.error("Failed to fetch user", error.response.data);
    }
  };

  useEffect(() => {
    getCurrentUser().then();
  }, []);

  return (
    <div className="app-content">
      <Sidebar/>
      <div className="home">
        <div className="home__img-wrapper">
          <img
            src={darkTheme ? introImgDark : introImgLight}
            alt=""
            className="home__img"
          />
        </div>

        <h1 className="home__title">Welcome to Talo</h1>
      </div>
    </div>
  );
};

export default Home;
