import React, {useState} from 'react';
import axios from 'axios';
import {Typography, Stack, Snackbar, Slide, IconButton, InputAdornment} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {DefaultInput} from "../../components/common/CustomTextField";
import {Link} from "react-router-dom";
import {Alert} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import apiClient from "../../api/apiClient";


const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [showPassword, setShowPassword] = useState(true);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data }  = await apiClient.post("/auth/login", {
                username: formData.username,
                password: formData.password,
            });
            localStorage.setItem("authToken", data.token);
            setLoading(false)
            navigate('/')
        } catch (err) {
            const {response} = err;
            setError(response?.data?.message || 'Login failed!');
            setOpen(true);
            setLoading(false)
        }
    };

    return (
        <Stack sx={{height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="h2" fontWeight={'bold'} color={'deepskyblue'} mb={3}>
                Talo
            </Typography>
            <Stack sx={{
                gap: 3,
                width: {xs: '80vw', md: 400},
                padding: 3,
                borderRadius: 5,
                backgroundColor: 'rgba(255,255,255,0.05)'
            }}>
                <Stack sx={{alignItems: 'center'}}>
                    <Typography fontSize={18} fontWeight={'bold'} color={'#f6f6f6'}>
                        Log in to Talo
                    </Typography>
                </Stack>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    TransitionComponent={'up'}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert
                        onClose={() => setOpen(false)}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {error}
                    </Alert>
                </Snackbar>
                <form onSubmit={handleSubmit}>
                    <Stack sx={{gap: 3, alignItems: 'center'}}>
                        <DefaultInput
                            className="number-input"
                            placeholder="Phone number"
                            variant="outlined"
                            type={'number'}
                            name="username"
                            autoComplete="off"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />

                        <DefaultInput
                            placeholder="Password"
                            variant="outlined"
                            name="password"
                            type={showPassword ? 'password' : 'text'}
                            autoComplete="off"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            fullWidth
                            loading={loading}
                            sx={{
                                backgroundColor: '#164863',
                                height: 50,
                                borderRadius: '30px',
                                textTransform: 'initial',
                                fontSize: 16, fontWeight: 'bold',
                                "&.Mui-disabled": {
                                    cursor: "not-allowed",
                                    background: "rgba(45,143,197,0.2)",
                                },
                            }}
                        >
                            Log in
                        </LoadingButton>

                        <Link to={'/signup'}>
                            Sign up for Talo
                        </Link>
                    </Stack>
                </form>
            </Stack>
        </Stack>
    );
};

export default Register;
